<template>
    <!-- //简单表格 -->
    <div class="Short_form">
        <!-- :height="high" -->
        <div v-if="high">
            <el-table :data="tabledata" style="width: 100%" :stripe="true" empty-text="暂无数据" :height="high"
                :row-style="{ height: row_high }" :header-row-style="{ height: row_high }">
                <el-table-column v-for="(item, index) in construction" :key="index" :prop="item.fieldName"
                    :label="item.title" align="center" :formatter="slashType == 'close' ? undefined : formatCleanliness">
                </el-table-column>
            </el-table>
        </div>
        <div v-else>
            <el-table :data="tabledata" style="width: 100%" :stripe="true" empty-text="暂无数据"
                :row-style="{ height: row_high }" :header-row-style="{ height: row_high }">
                <el-table-column v-for="(item, index) in construction" :key="index" :prop="item.fieldName"
                    :label="item.title" align="center" :formatter="slashType == 'close' ? undefined : formatCleanliness">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    //import引入组件才能使用
    components: {},
    props: ['construction', 'tabledata', 'high', 'row_high', 'slashType', 'type', 'version'],
    data() {
        return {
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        formatCleanliness(row, column, cellValue, index) {
            if (this.type === '月测') {
                return cellValue;
            }
            if (this.version === 1) {
                if (cellValue === 0 || cellValue === '0%' || cellValue === '') {
                    return '/';
                }
                return cellValue;
            } else {
                switch (cellValue) {
                    case '':
                        return '/';
                    default:
                        return cellValue;
                }
            }
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {},
}
</script>
<style scoped lang="less">
.Short_form {
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #e4e4e4;
        border-radius: 3px;
    }

    //滚动条的滑块
    ::-webkit-scrollbar-thumb {
        background-color: #c8c9cc;
        border-radius: 3px;
    }
    /deep/.el-table {
        border-radius: 10px;
        border: 1px solid #E8E8E8;

        .el-table__body {
            width: 100% !important;
        }

        th {
            text-align: center;
            background: #F2FBF9;
            color: #01B58C;
        }
    }
}
</style>